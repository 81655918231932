<template>
    <div class="mt-10 pt-10 text-center">
        <div class="display-1">{{ $t("browserNotSupported.label") }}</div>
        <div class="headline mt-10">{{ $t("browserNotSupported.text") }}</div>
    </div>
</template>

<script>
    export default {
        name: 'NotSupported'
    }
</script>

<style lang="scss" scoped>

</style>